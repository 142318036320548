<!-- 通用视频播放 -->
<template>
  <div class="video-player">
    <video :src="url" ref="videoEl" @click="pauseVideo"></video>
    <div class="mask" v-show="maskShow">
      <img src="@/assets/images/video/pause.png" class="pause-btn" @click="playVideo">
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       maskShow: true,
    }
  },
  props: {
    url: {
      type: String,
    }
  },
  components: {
    
  },
  mounted() {
    this.$refs.videoEl.addEventListener('play', () => {
      this.maskShow = false;
    })
    this.$refs.videoEl.addEventListener('pause', () => {
      this.maskShow = true;
    })
  },
  methods: {
    playVideo() {
      this.$refs.videoEl.play();
      this.maskShow = false;
    },
    pauseVideo() {
      this.$refs.videoEl.pause();
      this.maskShow = true;
    }
  },
}
</script>
<style scoped lang="less">
  .video-player{
    width: 100%;
    height: 100%;
    position: relative;
    video {
      width: 100%;
      height: 100%;
    }
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      .pause-btn{
        width: 10%;
        cursor: pointer;
      }
    }
  }
</style>