<!-- 视频介入展示 -->
<template>
  <div class="quick-access">
    <div class="container">
      <h3>{{title}}</h3>
      <video-player :url="url"></video-player>
      <ul class="column-list">
        <li>
          <span></span>
          <p>{{introduce}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/common/VideoPlayer';
export default {
  name: '',
  data() {
    return {
      
    }
  },
  components: {
    VideoPlayer,
  },
  computed: {
    url() {
      return this.$route.query.url
    },
    introduce() {
      return this.$route.query.introduce
    },
    title() {
      return this.$route.query.title
    },
  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.quick-access {
  h3{
    font-size: 2.5rem;
  }
  p{
    font-size: 1.5rem;
    color: #0B1145;
    &.title{
      color: #0B1145;
    }
  }
  .column-list {
    padding-bottom: 5rem;
  }
}
</style>